import React, { createContext, useContext, useState } from 'react';
import './spinner.css'; // CSSをインポート

// Loading Contextの作成
const LoadingContext = createContext();

// Loading Providerの作成
export const LoadingProvider = ({ children }) => {
  const [loadingCount, setLoadingCount] = useState(0);

  const startLoading = () => {
    setLoadingCount((count) => count + 1);
  };

  const endLoading = () => {
    setLoadingCount((count) => Math.max(0, count - 1));
  };

  return (
    <LoadingContext.Provider
      value={{ loading: loadingCount > 0, startLoading, endLoading }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

// Custom Hookの作成
export const useLoading = () => {
  const context = useContext(LoadingContext);

  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }

  return context;
};

// Spinner Componentの作成
export const Spinner = () => (
  <img
    className="spinner"
    style={{ marginTop: '248px', minHeight: '1vh' }}
    src={process.env.PUBLIC_URL + '/rotating_icon.png'}
    alt="Loading..."
  />
);
