import { Button, makeStyles, Badge, useMediaQuery } from '@material-ui/core';

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';

import OnOffByScroll from '../Animation/onOffByScroll';
import ContentTitle from '../General/contentTitle';
import various from '../General/various';
import imageIndex from '../img/imageIndex';
import { seminarIndex } from '../Apis/apis';
import { images as imageApi } from '../Apis/apis';
import Footer from '../Footer/footer';
import { ogpGenerator } from '../ogp';
import Clock from '../General/clock';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    marginTop: '168px',
  },

  badge: {},
  badgeEnd: {},
  secretBadge: {},
  paymentBadge: {},
  badghContainer: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '16px',
    '& > span': {
      display: 'block',
      minWidth: '114px',
      marginRight: '-32px',
      '& > span': {
        fontSize: '0.7rem',
        color: '#FFF',
        left: '32px',
      },
      '& $badge': {
        backgroundColor: theme.palette.primary.main,
      },
      '& $badgeEnd': {
        backgroundColor: 'grey',
      },
      '& $secretBadge': {
        width: '56px',
        backgroundColor: theme.palette.background.default,
        color: '#000',
        border: '1px solid #000',
      },
      '& $paymentBadge': {
        backgroundColor: '#dbebc4',
        color: '#000',
      },
    },
  },

  hashTags: {
    textAlign: 'start',
    lineHeight: '16px',
    '& > button': {
      fontWeight: 'bold',
      padding: '0px 8px 0px',
      '& > span': {
        fontSize: '1rem',
        fontStyle: 'italic',
        '@media screen and (max-width:400px)': {
          fontSize: '0.9rem !important',
        },
      },
      '& #active': {
        color: theme.palette.primary.main,
        fontWeight: '900',
      },
    },
  },
  hashButton: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    lineHeight: '16px',
    '& > span': {
      fontSize: '1rem',
      fontStyle: 'italic',
      '@media screen and (max-width:400px)': {
        fontSize: '0.9rem !important',
      },
    },
    '& #active-h': {
      color: theme.palette.primary.main,
      fontWeight: '900',
    },
  },

  container: {
    display: 'flex',
    flexFlow: 'column',
    marginTop: '80px',
  },

  content: {},
  dateHash: {},
  title: {},
  description: {},
  instWrapper: {},
  instructor: {},
  spec: {},
  read: {},
  contentImage: {
    width: 'calc(70vw * 0.3)',
    marginRight: '56px',
    '& > a': {
      '& > img': {
        width: 'calc(70vw * 0.3)',
        height: 'calc(70vw * 0.3)',
        objectFit: 'cover',
        // filter:
        //   'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(2px 2px 2px black)',
        filter: 'drop-shadow(2px 2px 2px black)',
      },
    },
  },
  contentContainer: {
    // height: 'calc(70vw * 0.3)',
    minHeight: '160px',
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '128px',

    '& $content': {
      width: 'calc(70vw * 0.7)',
      '& $dateHash': {
        display: 'flex',
        textAlign: 'start',
        fontWeight: 'bold',
        fontSize: '0.9rem',
        '& > span': {
          display: 'inline',
          marginRight: '16px',
          lineHeight: '16px',
          '@media screen and (max-width:500px)': {
            '& > span': {
              display: 'none',
            },
          },
        },
        '& > div': {
          display: 'flex',
          alignItems: 'start',
          flexWrap: 'wrap',
          width: '65%',
          lineHeight: '8px',
          '& > button': {
            padding: '0px 2px 0px 0px',
            '& > span': {
              '& > span': {
                fontSize: '0.8rem',
              },
            },
          },
        },
      },
      '& $title': {
        width: '100%',
        height: 'calc(70vw * 0.3 * 0.3)',
        minHeight: '32px',
        display: 'flex',
        margin: '0px 0px 4px',
        '& > a': {
          display: 'flex',
          alignItems: 'center',
          textAlign: 'start',
          padding: 0,
          '& > span': {
            fontSize: '1.8rem',
            fontWeight: 'bold',
            fontFamily: theme.typography.fontFamily,
            lineHeight: '28px',
            overflowWrap: 'break-word',
            // margin: '8px 0px',
            '& > span': {
              color: `${theme.palette.primary.main} !important`,
            },
          },
          '&:link, &:visited': {
            color: '#000',
          },
        },
      },
      '& $description': {
        width: 'calc(70vw * 0.7)',
        display: 'flex',
        marginTop: 'auto',
        '& $instWrapper': {
          width: 'calc(70vw * 0.7 * 0.2)',
          height: 'calc(70vw * 0.7 * 0.2)',
          margin: '4px 24px 0px 0px',
          '& $instructor': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'start',
            '& > img': {
              width: 'calc(70vw * 0.7 * 0.2)',
              height: 'calc(70vw * 0.7 * 0.2)',
              filter:
                'contrast(100%) grayscale(100%) saturate(0%) drop-shadow(1px 1px 0px black)',
              objectFit: 'cover',
            },
            '& > span': {
              display: 'inline-block',
              fontSize: '1vw',
              marginTop: '2px',
            },
          },
        },
        '& $spec': {
          width: 'calc(70vw * 0.7 * 0.2)',
          height: 'calc(70vw * 0.7 * 0.2)',
          minWidth: '120px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px 0px',
          marginRight: '16px',
          '& > div': {
            display: 'flex',
            flexWrap: 'nowrap',
            '& >div': {
              minWidth: '50px',
              fontSize: '0.7rem',
              textAlignLast: 'justify',
              textJustify: 'inter-ideograph', // IE Edge
            },
            '& > span': {
              display: 'inline-block',
              fontSize: '0.7rem',
            },
          },
        },
        '& $read': {
          width: 'calc(70vw * 0.7 * 0.6)',
          height: 'calc(70vw * 0.7 * 0.2)',
          minHeight: '80px',
          position: 'relative',
          overflow: 'hidden',
          lineHeight: '20px',
          whiteSpace: 'pre-wrap',
          textAlign: 'start',
          cursor: 'pointer',
          '& > a': {
            color: '#000',
            '& > span': {
              display: 'inline-block',
              fontSize: '0.9rem',
            },
          },
          '&::before': {
            content: '""',
            position: 'absolute',
            borderStyle: 'none',
            left: 0,
            bottom: 0,
            zIndex: 2,
            width: '100%',
            height: '120px',
            background: `linear-gradient(rgba(255,255,255,0) 0, ${theme.palette.background.default} 114px)`,
            pointerEvents: 'none',
          },
        },
      },
    },
    '@media screen and (max-width:1000px)': {
      marginBottom: '114px',
      '& $content': {
        width: '100%',
        '& $title': {
          margin: '8px 0px 8px 0px !important',
          '& > a': {
            '& > span': {
              fontSize: 'clamp(0.9rem, 1.2rem, 1.5rem) !important',
              lineHeight: '20px !important',
            },
          },
        },
        '& $description': {
          width: '100% !important',
          '& $instWrapper': {
            minWidth: '88px',
            minHeight: '88px',
            '& $instructor': {
              '& > img': {
                minWidth: '88px',
                minHeight: '88px',
                filter: 'drop-shadow(2px 2px 0px black) !important',
              },
            },
          },
          '& $spec': {
            gap: '2px 0px !important',
          },
          '& $read': {
            width: '100% !important',
          },
        },
      },
      '& $contentImage': {
        display: 'none !important',
      },
    },
    '@media screen and (max-width:400px)': {
      '& $content': {
        '& $description': {
          '& $spec': {
            width: '50% !important',
            '& > div': {
              justifyContent: 'start',
              '& > div': {
                marginRight: '16px',
              },
              '& > span': {
                textAlign: 'start',
              },
            },
          },
          '& $read': {
            display: 'none',
          },
        },
      },
    },
  },
  arrow: {
    width: '100%',
    margin: '144px auto',
    '& > button': {
      '& > span': {
        '& > img': {
          display: 'inline-block',
          width: '100px',
          color: '#000',
        },
      },
    },
  },
}));

const Seminar = () => {
  const TIMEZONE = various.timezone;
  const [image, setImage] = useState();
  const [viewSet, setViewSet] = useState(1);
  const [sort, setSort] = useState([]);
  const [hash, setHash] = useState([]);
  const [activeHash, setActiveHash] = useState();
  const [seminar, setSeminar] = useState();
  const [badge, setBadge] = useState([]);
  const [smallWidth, setSmallWidth] = useState(false);

  const element = useRef();
  const location = useLocation();
  const matches = useMediaQuery('(max-width:1000px)');

  const classes = useStyles();
  const seminarAreaTitle = {
    titleList: ['Seminar'],
    sub: 'セミナー',
  };

  // if (seminar) console.log('seminar', seminar);

  // OGP設定
  const ogp = useCallback((props) => {
    ogpGenerator(props);
  }, []);

  useLayoutEffect(() => {
    if (image) {
      const ogpProps = {
        page: 'seminar',
        pageTitle: '刀禪オフィシャルWEBサイトセミナー一覧 。',
        path: location.pathname,
        imageUrl: image.OfficialImage_TouzenLogoBlack.url,
      };
      ogp(ogpProps);
    }
  }, [image, location, ogp]);
  // OGP設定ここまで

  useEffect(() => {
    imageApi(setImage);
  }, []);

  useEffect(() => {
    if (matches) {
      setSmallWidth(true);
    } else {
      setSmallWidth(false);
    }
  }, [matches]);

  useEffect(() => {
    let result = [];
    const NOW = Clock({ timezone: TIMEZONE }).time;
    if (sort) {
      sort.forEach((el) => {
        const dead = Clock({
          timezone: TIMEZONE,
          time: null,
          string: el.section1Start,
        }).time;
        if (dead < NOW) {
          result.push(false);
        } else {
          result.push(true);
        }
      });
    }
    setBadge(result);
  }, [sort, TIMEZONE]);

  useEffect(() => {
    if (seminar) {
      const contents = seminar.slice(0, 8 * viewSet);
      setSort(contents);
    }
  }, [seminar, hash, viewSet]);

  useEffect(() => {
    let resultHash = [];
    if (seminar) {
      seminar.forEach((elm) => {
        elm.hash.forEach((hash_tag) => {
          resultHash.push(hash_tag);
        });
      });
    }
    let set = new Set(resultHash);
    setHash(Array.from(set));
  }, [seminar]);

  useEffect(() => {
    const getSeminar = async () => {
      await seminarIndex(setSeminar);
    };
    getSeminar();
  }, []);

  const handleSort = (e, key) => {
    e.preventDefault();
    let result = [];
    seminar.forEach((elm) => {
      if (elm.hash.includes(key)) {
        result.push(elm);
      }
    });
    setSort(result);
    setActiveHash(key);
  };
  const handleClick = (e) => {
    e.preventDefault();
    setViewSet(viewSet + 1);
  };

  const titleSplitter = (element) => {
    // eslint-disable-next-line
    let match = element.title.match(/^([【「\[\]',"（）()]+)(.*)$/);
    let firstPart = match ? match[1] : '';
    let secondPart = match ? match[2] : element.title;
    return (
      <span>
        {firstPart}
        <span>{secondPart.slice(0, 1)}</span>
        {secondPart.slice(1)}&nbsp;{element.num ? `# ${element.num}` : null}
      </span>
    );
  };
  const seminarMappingTextVer = () => {
    if (sort) {
      return sort.map((elm, index) => {
        // console.log('elm', elm);
        return (
          <div key={index} className={classes.contentContainer}>
            <div
              ref={index === 0 ? element : undefined}
              id={index === 0 ? `target_seminar_${index}` : undefined}
              className={classes.contentImage}
            >
              <Link to={`${various.general.seminar}/${elm.id}`}>
                <img src={elm.image} alt={`seminarImage_${index + 1}`} />
              </Link>
            </div>
            <div className={classes.content}>
              <div className={classes.badghContainer}>
                <Badge
                  badgeContent={badge[index] ? '応募受付中' : '受付終了'}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  classes={
                    badge[index]
                      ? { badge: classes.badge }
                      : { badge: classes.badgeEnd }
                  }
                  overlap="rectangular"
                  variant="standard"
                />
                {elm.is_secret ? (
                  <Badge
                    badgeContent={'秘伝'}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    classes={{ badge: classes.secretBadge }}
                    overlap="rectangular"
                    variant="standard"
                  />
                ) : null}
                {elm.payment_method?.is_only_cash ? (
                  <Badge
                    badgeContent={'現金払いのみ'}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    classes={{ badge: classes.paymentBadge }}
                    overlap="rectangular"
                    variant="standard"
                  />
                ) : null}
              </div>
              <div className={classes.dateHash}>
                <span>
                  <span id="open">開催&nbsp;:&nbsp;</span>
                  {
                    Clock({
                      timezone: TIMEZONE,
                      time: null,
                      string: elm.date,
                    }).today
                  }
                </span>
                <div>
                  {elm.hash.map((h, i) => {
                    return (
                      <Button
                        key={i}
                        onClick={(e) => handleSort(e, h)}
                        classes={{ root: classes.hashButton }}
                      >
                        {h === activeHash ? (
                          <span id="active-h">{h}&nbsp;</span>
                        ) : (
                          <span>{h}&nbsp;</span>
                        )}
                      </Button>
                    );
                  })}
                </div>
              </div>
              <div className={classes.title}>
                <Link to={`${various.general.seminar}/${elm.id}`}>
                  {titleSplitter(elm)}
                </Link>
              </div>
              <div className={classes.description}>
                {elm.show_the_instructor ? (
                  <div className={classes.instWrapper}>
                    <div className={classes.instructor}>
                      {smallWidth ? (
                        <img src={elm.image} alt="seminar_image" />
                      ) : (
                        <>
                          <img src={elm.instructor} alt="instructor" />
                          <span>講師:{elm.instructorName}</span>
                        </>
                      )}
                    </div>
                  </div>
                ) : smallWidth ? (
                  <div className={classes.instWrapper}>
                    <div className={classes.instructor}>
                      <img src={elm.image} alt="seminar_image" />
                    </div>
                  </div>
                ) : undefined}
                <div className={classes.spec}>
                  <div>
                    <div>対象者&nbsp;:&nbsp;</div>
                    <span>{elm.target}</span>
                  </div>
                  <div>
                    <div>時刻&nbsp;:&nbsp;</div>
                    <span>
                      {
                        Clock({
                          timezone: TIMEZONE,
                          time: null,
                          string: elm.section1Start,
                        }).hourMinutes
                      }
                      &nbsp;~&nbsp;
                    </span>
                  </div>
                  <div>
                    <div>定員&nbsp;:&nbsp;</div>
                    <span>{elm.cap}名&nbsp;/&nbsp;部</span>
                  </div>
                  <div>
                    <div>場所&nbsp;:&nbsp;</div>
                    <span>{elm.place}&nbsp;</span>
                  </div>
                </div>
                <div className={classes.read}>
                  <Link to={`${various.general.seminar}/${elm.id}`}>
                    <span>{elm.read}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <>
      <div>
        <OnOffByScroll place="General" />
      </div>
      {location.pathname === various.general.seminar ? (
        <>
          <div className={classes.contentTitle}>
            <a href={various.general.seminar}>
              <ContentTitle title={seminarAreaTitle} />
            </a>
          </div>
          <div className={classes.hashTags}>
            {hash.map((hash, index) => (
              <Button onClick={(e) => handleSort(e, hash)} key={index}>
                {hash}
              </Button>
            ))}
          </div>
          <div className={classes.container}>{seminarMappingTextVer()}</div>
          <div className={classes.arrow}>
            <Button onClick={handleClick}>
              <img src={imageIndex.semianrPage.downArrow} alt="arrow" />
            </Button>
          </div>
          <Footer />
        </>
      ) : (
        <Outlet context={{ contentElements: seminar }} />
      )}
    </>
  );
};

export default Seminar;
